<template>
  <div class="home" id="quotaReviewList">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="交易流水" name="third">
        <div style="text-align: left" >
          <el-form :inline="true" :model="queryForm" class="demo-form-inline">
            <el-form-item label="交易方名称：">
              <el-input v-model="queryForm.tradingPartyName" placeholder="请输入查询" :clearable="true"></el-input>
            </el-form-item>
            <el-form-item label="交易类型：">
              <el-select v-model="queryForm.type" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="交易时间:">
            <el-date-picker value-format="yyyy-MM-dd HH:mm:ss"
                        v-model="queryForm.time"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="起"
                        end-placeholder="止"
                        :default-time="['00:00:00','23:59:59']">
                    </el-date-picker>
              </el-form-item>
              <el-form-item class="search">
                <el-button type="primary" @click="loadTable" icon="el-icon-search">查询</el-button>
                <el-button type="primary" @click="onReset" icon="el-icon-refresh-right">重置</el-button>
              </el-form-item>
            </el-form>
        </div>
        
        <div>
          <el-table
            :data="tableData"
            :header-cell-style="tableHeader"
            border>
            <el-table-column prop="sss" label="序号" width="50" align="center">
              <template slot-scope="scope">
                <span>
                  {{
                  scope.$index + 1 + (queryForm.pageNo - 1) * queryForm.pageSize
                  }}
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="createTime" label="交易时间" :show-overflow-tooltip="true" align="center" width="200px"/>
            <el-table-column prop="id" label="流水号" :show-overflow-tooltip="true" align="center" width="200px"/>
            <el-table-column prop="accountName" label="子账户" :show-overflow-tooltip="true" align="center" width="200px"/>
            <el-table-column label="交易金额(元)" align="center"><template slot-scope="scope" >{{accDiv(scope.row.amount,100)}}</template></el-table-column> 
            <el-table-column prop="type" label="交易类型" align="center"  :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <span v-if="scope.row.type == '1'">入金</span>
                <span v-if="scope.row.type == '2'">转账</span>
                <span v-if="scope.row.type == '3'">出金</span>
                <span v-if="scope.row.type == '4'">非同名出金</span>
                <span v-if="scope.row.type == '5'">线下入金</span>
              </template>
            </el-table-column>
            <el-table-column prop="remark" label="资金用途" align="center" :show-overflow-tooltip="true" width="200px"/>
            <el-table-column prop="tradingPartyAccNo" label="交易方账号" :show-overflow-tooltip="true" width="200px" align="center" />
            <el-table-column prop="tradingPartyName" label="交易方名称" :show-overflow-tooltip="true" width="200px" align="center" />
            <el-table-column prop="amountType" label="收支类型" align="center"  :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <span v-if="scope.row.amountType == '1'">收入</span>
                <span v-if="scope.row.amountType == '2'">支出</span>
              </template>
            </el-table-column>
            <el-table-column prop="state" label="交易状态" align="center" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <span v-if="scope.row.state == '1'">处理中</span>
                <span v-if="scope.row.state == '2'">成功</span>
                <span v-if="scope.row.state == '3'">失败</span>
                <span v-if="scope.row.state == '4'">部分成功</span>
              </template>
            </el-table-column>
            
            
          </el-table>
          <!-- 分页栏 -->
          <el-pagination
            id="page"
            background
            align="right"
            @size-change="handleSizeChange"
            @current-change="salarysizeChange"
            :page-sizes="[5,10, 15, 20]"
            :page-size="queryForm.pageSize"
            :current-page="queryForm.pageNo"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </el-tab-pane>

      <el-tab-pane label="提现记录" name="first">
         <div style="text-align: left" >
          <el-form :inline="true" :model="queryForms" class="demo-form-inline">
            <el-form-item label="金额：">
              <el-input v-model="queryForms.money" placeholder="请输入查询" :clearable="true"></el-input>
            </el-form-item>
            <el-form-item label="备注：">
              <el-input v-model="queryForms.transferComment" placeholder="请输入查询" :clearable="true"></el-input>
            </el-form-item>
            <el-form-item label="交易时间:">
            <el-date-picker value-format="yyyy-MM-dd HH:mm:ss"
                        v-model="queryForms.time"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="起"
                        end-placeholder="止"
                        :default-time="['00:00:00','23:59:59']">
                    </el-date-picker>
              </el-form-item>
              <el-form-item class="search">
                <el-button type="primary" @click="loadTables" icon="el-icon-search">查询</el-button>
                <el-button type="primary" @click="onResets" icon="el-icon-refresh-right">重置</el-button>
              </el-form-item>
            </el-form>
        </div>
        
        <div>
          <el-table
            :data="tableDatas"
            :header-cell-style="tableHeader"
            border>
            <el-table-column prop="sss" label="序号" width="50" align="center">
              <template slot-scope="scope">
                <span>
                  {{
                  scope.$index + 1 + (queryForm.pageNo - 1) * queryForm.pageSize
                  }}
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="createTime" label="交易时间" :show-overflow-tooltip="true" align="center" width="200px"/>
            <el-table-column prop="tranNo" label="流水号" :show-overflow-tooltip="true" align="center" width="200px"/>
            <el-table-column prop="tranAmount" label="交易金额(元)" align="center" width="200px" />
            <el-table-column prop="reqsts" label="请求状态" align="center"  :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <span v-if="scope.row.reqsts == 'AUT'">	等待审批</span>
                <span v-if="scope.row.reqsts == 'NTE'">终审完毕</span>
                <span v-if="scope.row.reqsts == 'BNK'">银行处理中</span>
                <span v-if="scope.row.reqsts == 'FIN'">完成</span>
                <span v-if="scope.row.reqsts == 'OPR'">数据接收中</span>
                <span v-if="scope.row.reqsts == 'APW'">银行人工审批 </span>
                <span v-if="scope.row.reqsts == 'WRF'">人工介入处理</span>
              </template>
            </el-table-column>
            <el-table-column prop="rtnflg" label="业务处理结果" align="center" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <span v-if="scope.row.rtnflg == 'S'">银行支付成功</span>
                <span v-if="scope.row.rtnflg == 'F'">银行支付失败</span>
                <span v-if="scope.row.rtnflg == 'B'">银行支付被退票</span>
                <span v-if="scope.row.rtnflg == 'R'">企业审批否决</span>
                <span v-if="scope.row.rtnflg == 'D'">企业过期不审批</span>
                <span v-if="scope.row.rtnflg == 'C'">企业撤销</span>
              </template>
            </el-table-column>
            <el-table-column prop="rtnnar" label="失败原因" :show-overflow-tooltip="true" width="200px" align="center" />
            <el-table-column prop="remark" label="备注" align="center" :show-overflow-tooltip="true" width="200px"/>
            
            
          </el-table>
          <!-- 分页栏 -->
          <el-pagination
            id="page"
            background
            align="right"
            @size-change="handleSizeChanges"
            @current-change="salarysizeChanges"
            :page-sizes="[5,10, 15, 20]"
            :page-size="queryForms.pageSize"
            :current-page="queryForms.pageNo"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totals"
          ></el-pagination>
        </div>
      </el-tab-pane>
   
    </el-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: 'third',
      queryForm:{
        name:'',
        idno:'',
        time:[],
        minCreateTime:'',
        maxCreateTime:'',
        tenant:'',
        pageNo:1,
        pageSize:10,
        tradingPartyName:'',
        type:'',
      },
      queryForms:{
        money:'',
        transferComment:'',
        time:[],
        pageNo:1,
        pageSize:10,
      },
      options: [{
          value: '1',
          label: '入金'
        }, {
          value: '2',
          label: '转账'
        }, {
          value: '3',
          label: '出金'
        }, {
          value: '4',
          label: '非同名出金'
        }, {
          value: '5',
          label: '线下入金'
        }],
      total:0,
      tenants:'',
      tableData:[],
      tableDatas:[],
      total:0,
      totals:0,
      itemList:[],
      itemLists:[],
      diction: {
          code: "summaryStatus",
      },
      
      PayrollInfo:'',
      loading: false,
      salaryInfo:{
        buscod:'',
        yurref:'',
        bgndat:'',
        enddat:'',
        salarySummarizingId:'',
        salarySummarizingRecordsId:'',
        zhtenantID:'',
        createTime:'',
      },
      
    };
  },
  async created() {
    this.finTenantInfo();
    this.loadTable();
    this.loadTables();
    
    
  },
  // 挂载查询方法
  mounted() {
  },
  
  methods: {
    handleClick(tab, event) {
        console.log(tab, event);
      },
    // 查询
    async loadTable() {
      this.tableLoading = true;
      var time0 = this.queryForm.time ? this.queryForm.time[0] : ''
      var time1 = this.queryForm.time ? this.queryForm.time[1] : ''
      this.queryForm.minCreateTime = time0
      this.queryForm.maxCreateTime = time1
      await this.api.salaryApi.accountTransInfo(this.queryForm).then(res => {
          res = res.data;
          if(res.code == 200){
            this.tableData = res.data.data.records;
            this.total = res.data.data.total
            // console.log("---------交易数据",this.tableData);
          }else{
            this.tableData =  null;
            this.$message.error(res.msg);
          }
        });
    },
    async loadTables() {
      this.tableLoading = true;
      await this.api.salaryApi.list(this.queryForms).then(res => {
          res = res.data;
          console.log("---------交易数据11",res);
          if(res.code == 200){
            this.tableDatas = res.data.records;
            this.totals = res.data.total
            console.log("---------交易数据",this.tableDatas);
          }else{
            this.tableDatas =  null;
            this.$message.error(res.msg);
          }
        });
    },
    //查询租户信息
    async finTenantInfo() {
      this.tableLoading = true;
      await this.api.salaryApi.finTenantInfo(this.queryForm).then(res => {
          this.tenantInfo = res.data;
          console.log("-----租户",this.tenantInfo)
          this.queryForm.tenant = this.tenantInfo.id
        });
    },

    // 分页改变时触发
    salarysizeChange(page) {
      this.queryForm.pageNo = page;
      this.loadTable();
    },
    handleSizeChange(size) {
      this.queryForm.pageSize = size;
      this.loadTable();
    },
    
    // 重置
    onReset() {
      this.queryForm.time=[],
      this.queryForm.tradingPartyName='';
      this.queryForm.type='';
      this.queryForm.mobile='';
      this.loadTable();
    },
        // 分页改变时触发
    salarysizeChanges(page) {
      this.queryForms.pageNo = page;
      this.loadTables();
    },
    handleSizeChanges(size) {
      this.queryForms.pageSize = size;
      this.loadTables();
    },
    
    // 重置
    onResets() {
      this.queryForms.time=[],
      this.queryForms.money='';
      this.queryForms.transferComment='';
      this.loadTables();
    },

    // 导出
    derive() {},
    // async findDictionariesItemLists(param) {
    //   let response = await this.api.platformApi.findDictionariesItemList(param);
    //   console.log("数据字典", response);
    //   this.itemLists = response.data.data;
    // },
    tableHeader(column) {
      return "height:36px;background-color:#fafafa;color:#333333;padding:0";
    },
    afi(fmt){
      // Date.prototype.format = function(fmt) { 
          var o = { 
              "M+" : this.getMonth()+1,                 //月份 
              "d+" : this.getDate(),                    //日 
              "h+" : this.getHours(),                   //小时 
              "m+" : this.getMinutes(),                 //分 
              "s+" : this.getSeconds(),                 //秒 
              "q+" : Math.floor((this.getMonth()+3)/3), //季度 
              "S"  : this.getMilliseconds()             //毫秒 
          }; 
          if(/(y+)/.test(fmt)) {
                  fmt=fmt.replace(RegExp.$1, (this.getFullYear()+"").substr(4 - RegExp.$1.length)); 
          }
          for(var k in o) {
              if(new RegExp("("+ k +")").test(fmt)){
                  fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));
              }
          }
          return fmt; 
      // }       
    },
    //除法函数
    accDiv(arg1, arg2){
         if(arg1!=null){
          var t1 = 0, t2 = 0, r1, r2;
          try {
            t1 = arg1.toString().split(".")[1].length;
          }
          catch (e) {
          }
          try {
            t2 = arg2.toString().split(".")[1].length;
          }
          catch (e) {
          }
          r1 = Number(arg1.toString().replace(".", ""));
          r2 = Number(arg2.toString().replace(".", ""));
          return (r1 / r2) * Math.pow(10, t2 - t1);
         }
         else{
          return null
         }
        }
  }
};
</script>

<style scoped>
#quotaReviewList {
  overflow: hidden;
  background-color: white;
  padding: 15px 20px 100px;
}
.sys-info{
  display: flex;
  font-size: 20px;
  margin-bottom: 10px;
  background: #00FFFF;
  border-radius: 10px;
}
.info-a{
  margin: 0 100px 0 10px;
}
.info-b{

}
.info-jc{
  color: red;
  font-weight: 900;
  font-size: 24px;
}
.search{
  margin-left: 50px;
}
</style>
