<template>
  <div class="home" id="quotaReviewList">
    <div style="text-align: left" >
      <el-form :inline="true" :model="queryForm" class="demo-form-inline">
        <el-form-item label="模板名称：">
          <el-input v-model="queryForm.name" placeholder="请输入模板名称查询" :clearable="true"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="loadTable" icon="el-icon-search">查询</el-button>
          <el-button type="primary" @click="onReset" icon="el-icon-refresh-right">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div style="text-align: left;margin-bottom: 20px;">
      <el-button type="primary" icon="el-icon-plus" @click="openUploadFrom('上传模板:')">上传模板</el-button>
      <el-button type="primary" icon="el-icon-plus" @click="downloadSalary">下载签署资料模板模板</el-button>
    </div>
    <div>
        <el-table  :header-cell-style="tableHeader" :cell-style="tableCell" :data="tableData" fit border style="width: 100%">
        <el-table-column type="index" width="50" label="" />
        <el-table-column prop="templateId" :show-overflow-tooltip="true"  label="模板ID" />
        <el-table-column prop="name" :show-overflow-tooltip="true" label="模板名称" />
        <el-table-column prop="uploadTime" :show-overflow-tooltip="true" label="上传时间" />
        <el-table-column label="操作" >
          <template slot-scope="scope">
            <!-- <el-button @click="noDetails(scope.row)" type="text" size="small">签署</el-button>  -->
            <el-button @click="openUploadDialog(scope.row)" type="text" size="small">签署</el-button> 
          </template>
        </el-table-column>
      </el-table>
     <el-pagination
        id="page"
        background
        @size-change="sizeChange"
        @current-change="currentChange"
        @prev-click="currentChange"
        @next-click="currentChange"
        :page-sizes="[5, 10, 20]"
        :page-size="queryForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <div>
      <!-- 外层 -->
      <el-dialog :title="title" :visible.sync="dialogFormVisible" width="50%" style="text-align: initial;" @closed="afteraddOrUpdateDialogClose">

        <!-- 内层 -->
        

        <el-form :model="updateForm" :label-position="'left'" ref="updateForm" :rules="updateFormRules" label-width="150" class="updateForm" >
          <el-form-item :label="title"  prop="isFile" >

            <el-upload
              class="upload-demo"
              ref="upload"
              :action="upload"
              :on-preview="handlePreview"
              :on-error="handleError"
              :on-remove="handleRemove"
              :on-success="handlesuccess"
              :file-list="fileList"
              :limit=1
              :data="uploadJson"
              :auto-upload="false">
              <el-button slot="trigger" size="small" type="primary">选取模板</el-button>
              <div slot="tip" class="el-upload__tip">请上传签署模板。</div>
            </el-upload>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" v-loading.fullscreen.lock="fullscreenLoading" @click="submitUploadFrom">确 定</el-button>

        </div>
      </el-dialog>


          <!-- 签署信息 -->
      <el-dialog title="上传签署信息" :visible.sync="uploadDialog" width="50%" style="text-align: initial;" @closed="closedUpdateSign">
        <!-- 内层 -->
        <el-form :model="updateSignForm" :label-position="'left'" ref="updateSignForm" :rules="updateSignFormRules" label-width="150" class="updateForm" >
          <el-form-item label="上传签署信息"  prop="isFile" >
            <el-upload
              class="upload-demo"
              ref="updateSign"
              :action="updateSign"
              :on-success="signSuccess"
              :file-list="fileListSign"
              :limit=1
              :data="updateSignJson"
              accept=".xls,.xlsx"
              :auto-upload="false">
              <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
              <div slot="tip" class="el-upload__tip">只能上传xls/xlsx文件。</div>
            </el-upload>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="uploadDialog = false">取 消</el-button>
          <el-button type="primary" v-loading.fullscreen.lock="fullscreenLoading" @click="submitUpdateSignFrom">确 定</el-button>

        </div>
      </el-dialog>

    </div>
  </div>
</template>
<script>

export default {
    data() {
      return {
        uploadDialog: false,
        updateSignForm: {
          isFile: null,
        },
        updateSignFormRules: {
          isFile:[
            { required: true, message: '请选择文件！', trigger: 'change' }
          ]
        },
        updateSign: process.env.VUE_APP_BASE_URL+'/contract/batchSign',
        updateSignJson: {
          templateId: '',
        },
        fileListSign: [],
        
        // tableLoading: true,
        //upload: process.env.VUE_APP_BASE_URL+'/salarySummarizing/importSalary',
        upload:process.env.VUE_APP_BASE_URL+'/salarySummarizing/importPayrollDeclaration',
        // 0为汇总
        type: 0,
        title:'',//上传汇总表还是申报数据表
        queryForm: {
          name: '',
          groupIds: null,
          groupId: '',
          status: '',
          pageNo: 1,
          pageSize: 10
        },
        total:0,
        // 这个站点对象集合
        fullscreenLoading: false,
        options: [],
        // 表格对象
        tableData: [],
        // input: '',
        updateForm: {
          groupIds: [],
          groupId: this.queryFormGetGroupIds,
          groupName: '',
          sessionId: '',
          type: 0,
          creditLimitApprovalId: '',
          isFile: null,
        },
        updateFormRules: {
          isFile:[
             { required: true, message: '请选择文件！', trigger: 'change' }
          ]
        },
        uploadJson:{
          json: {},
          userId: '',
          sessionId:'',
          type: process.env.VUE_APP_UPLOAD_TYPE,
          queue: process.env.VUE_APP_UPLOAD_SALARY_SUMMARY_QUENCE_NAME
        },
        
        dialogFormVisible: false,
        innerVisible: false,
        fileList: [],
        auditMsg: {
          dialogVisible: false,
          msg: '确认撤销审核该汇总表吗？',
          msgClass: 'submitMsg',
          buttonClass: 'submitButton',
          // 提交为0，撤销为1
          status: 0
        },
        // 这个是解析表格
        parseTable:{
          headDate: '',
          tableData: [{date: '',id:'',name:'',money:'',comment:''}],

        },
        parsePage:{
          pageNo: 1,
          pageSize: 10,
        },
        queryParams: {
        pageNo: 1,
        pageSize: 10,
        code: "",
        name: "",
      },
      changeStateParams:{
        id: '',
        status: null,
        sessionId: ''
      },
      itemList: [],
      
      }
    },
    computed: {
      parseTableData(){
        return this.parseTable.tableData.slice((this.parsePage.pageNo-1)*this.parsePage.pageSize,this.parsePage.pageSize*this.parsePage.pageNo);
      },
      parseTableTotal(){
        return this.parseTable.tableData.length;
      },
    },
    watch: {
      queryFormGetGroupIds(newVal, oldVal){
        this.queryForm.groupId = newVal;
      }
    },
    // 实例创建完成后调用

    mounted: function(){
				this.findDictionariesItemList(this.diction);
		},
    async created(){
		let flag = this;
      
	  this.loadTable();
    },



    methods: {

    // 打开上传会话
    openUploadDialog(row){
      this.uploadDialog = true;
      this.updateSignJson.templateId = row.templateId;
    },
    // 上传成功
    signSuccess(){
      this.uploadDialog = false;
      this.fullscreenLoading = true;
      setTimeout(() => {
        this.fullscreenLoading = false;
        this.$message({
        message: '上传成功！',
        type: 'success'
      });
        // this.loadTable();
      }, 2000);
    },
    // 提交表格
    submitUpdateSignFrom(){
      console.log('开始上传');
      this.updateSignForm.isFile = this.$refs.updateSign.$data.uploadFiles;
      this.$refs['updateSignForm'].validate((valid) => {
        if (valid) {
          this.updateSignJson.sessionId = this.$getCookie("sessionId");
          this.$refs.updateSign.submit();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
      console.log('上传成功！');
    },
    // 关闭提交表格回话
    closedUpdateSign(){
      this.resetForm('updateSignForm');
      this.$refs.updateSign.$data.uploadFiles = [];
    },
       // 分页查看薪资信息列表
		async loadTable(){
			this.loading = true;
			let flag = this;
			//getStaffList
      console.log(this.queryForm);
			await this.api.contranctApi.templateList(this.queryForm).then((res) => {
				res = res.data
				console.log("----模板列表-----",res);
				// flag.loading = false;
				if(res.code == 200){
					flag.tableData = res.data.records;
					flag.total = res.data.total
				}else{
            this.tableData =  null;
            this.total = 0;
            this.$message.error(res.msg);
          }
			})
		},

    downloadSalary(){
        let link = document.createElement('a')
        link.style.display = 'none'
        link.href = process.env.VUE_APP_BASE_URL+process.env.VUE_APP_TEMPLATE;
        // VUE_APP_PAYROLL_TEMPLATE
        link.setAttribute('download', decodeURIComponent(process.env.VUE_APP_TEMPLATE))
        document.body.appendChild(link)
        link.click();
        window.URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
        console.log('下载成功！')
      },

      
      // 查看函数
      noDetails(row){
        this.$router.push({path: '/signContract',query: {templateId: row.templateId}})

      },
      // 作废
      cancellation(){
        
      },
      
      // 点击模板
      openUploadFrom(title){
        this.title = title;
        this.upload = process.env.VUE_APP_BASE_URL+'/contract/createTemplate'
        this.dialogFormVisible = true;
      },
     
      
      parseXlsxPageChange(page){
        console.log(page+'page');
        this.parsePage.pageNo = page;
      },
      //parseXlsxPagesizeChange 
      parseXlsxPagesizeChange(size){
        console.log(size+'size');
        this.parsePage.pageSize = size;
      },
      // 分页器 size改变是触发
       //current-change
      currentChange(page){
        this.queryForm.pageNo = page;
        this.loadTable();
      },

      // size-change
      sizeChange(size){
        this.queryForm.pageSize = size;
        this.loadTable();
      },
      
      // 上传表单
      submitUploadFrom(){
        console.log('开始上传');
        this.uploadJson.sessionId = this.$getCookie("sessionId");
        this.$refs.upload.submit();
        console.log('上传成功！');
        // this.loadTable();
        // this.$refs['updateForm'].validate((valid) => {
        //   if (valid) {
        //     console.log(111);
        //     if(!this.isEmpty(this.updateForm.groupIds)){
        //       this.updateForm.groupId = this.updateForm.groupIds[this.updateForm.groupIds.length-1]
        //       this.updateForm.groupName = this.$refs.uploadGroup.$data.inputValue;
        //       this.updateForm.sessionId = this.$getCookie("sessionId");
        //       this.uploadJson.json = JSON.stringify(this.updateForm);
              
        //     }
        //   } else {
        //     console.log('error submit!!');
        //     return false;
        //   }
        // });
      },
     
      // afteraddOrUpdateDialogClose 清空弹窗表单
      afteraddOrUpdateDialogClose(){
          this.resetForm('updateForm');
          this.$refs.upload.$data.uploadFiles = [];
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      // 下载
     
      // 重置
      onReset(){
        this.queryForm.name='';

      },
      // 上传表格
      submitUpload() {
        console.log('submitUpload!');
        this.$refs.upload.submit();
      },
      // 删除一选择的上传文件函数
      handleRemove(file, fileList) {
        console.log('handleRemove!');
        this.updateForm.isFile = null;
        console.log(file, fileList);
      },
      // 点击文件列表中已上传的文件时的钩子
      handlePreview(file) {
        console.log('handlePreview!');
        console.log(file);
      },
      // 上传失败的钩子
      handleError(err, file, fileList){
        this.$message.error('上传失败！');
      },
      // 上传成功的钩子
      handlesuccess(response, file, fileList){
        this.updateForm.groupId = [];
        this.fileList = []
        this.dialogFormVisible = false;
        this.fullscreenLoading = true;
        setTimeout(() => {
          this.fullscreenLoading = false;
          this.$message({
          message: '上传成功！',
          type: 'success'
        });
          this.loadTable();
        }, 2000);
        console.log('handlesuccess');
      },
     
      async findDictionariesItemList(param) {
				let response = await this.api.platformApi.findDictionariesItemList(param);
				console.log("数据字典", response);
				this.itemList = response.data.data;
			},
      

      tableHeader(column) {
        return "height:36px;background-color:#fafafa;color:#333333;padding:0";
      },
      tableCell(column) {
        return "height:40px;background-color:#fafafa;color:#000000;padding:0";
      },
    },
}
</script>

<style scoped>
  .submitMsg{
    color: #09BF88;
    font-size: 1.5rem;
    
  }
  .submitButton{
    background-color: #09BF88;
    border: none;
  }
  .submitButton:hover{
    background-color: #3ACCA0;
  }
  .repealMsg{
    color: #FFAC1D;
    font-size: 1.5rem;
  }
  .repealButton{
    background-color: #FFAC1D;
    border: none;
  }
  .repealButton:hover{
    background-color: #FFBD4A;
  }

  .cancellationMsg{
    color: #F78969;
    font-size: 1.5rem;
  }
  .cancellationButton{
    background-color: #F78969;
    border: none;
  }
  .cancellationButton:hover{
    background-color: #F789;
  }
/* -------------------------------------- */

#page {
  float: right;
  margin-top: 15px;
}

#quotaReviewList {
  overflow: hidden;
  background-color: white;
  padding: 15px 20px 100px;
}

.el-dialog__body {
  border-top: 1px solid rgba(228, 228, 228, 1);
  border-bottom: 1px solid rgb(228, 228, 228, 1);
  padding-left: 0;
  padding-right: 0;
}

.el-drawer.rtl {
  overflow: scroll;
}

.updateForm{
  width: 90%;
  margin: 0 auto;
}
.parseXlsxBottom{
  text-align: end;
  padding: 20px;
}
</style>


