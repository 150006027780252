<template>
  <div>
    <div v-for="(item, i) in list" :key="index+'-'+i">
      <el-submenu :index="index+'-'+i" v-if="item.children != null">
        <template slot="title">
          <img :src="iconMap[item.name]" alt="" style="width: 16px; height: 16px;margin-right: 8px;">
          <span>{{item[title]}}</span>
          
        </template>
        <tree-menus :list="item.children" :index="index+1" :route="route" :title="title" :children="children"></tree-menus>
      </el-submenu>
      <el-menu-item v-else :index="item[route]">
        {{item[title]}}
        <!-- <span slot="title"></span> -->
      </el-menu-item>
    </div>
  </div>
</template>

<script>
export default {
  name: "treeMenus",
  props: {
    'list': {
      type: Array,
      default: []
    },
    'route': {
      type: String,
      default: 'route'
    },
    'title':{
      type: String,
      default: 'title'
    },
    'index':{
      type: Number,
      default: 0
    },
    'children':{
      type: String,
      default: 'children'
    }
  },
  data(){
      return {
        iconMap:{
          '首页': require('@/static/system/images/shouye.png'),
          '系统管理':require('@/static/system/images/xitong.png'),
          '基础设置':require('@/static/system/images/jichu.png'),
          '薪资管理':require('@/static/system/images/xinzi.png'),
          '发票管理':require('@/static/system/images/fapiao.png')
        }
      }
    }
};
</script>
<style scoped>
>>> .el-submenu__icon-arrow.el-icon-arrow-down{
  color: #fff;
}
>>> .el-submenu.is-opened .el-submenu__title{
  background-image: url('~@/static/system/images/menu-active.png');
  background-repeat: no-repeat;
  background-size: 95% 70%;
  background-position: center center;
}
>>> .el-submenu__title{
  font-weight: bold;
  font-size: 16px;
  line-height: 60px;
}
.el-menu-item {
  /* padding-left: 85px !important; */
  border-bottom: 1px solid rgb(153, 153, 153, 0.2);
  position: relative;
  padding-left: 40px;
}
#menutree .el-menu-item.is-active {
  /* background-color: #e6f7ff !important; */
  border-right: 3px solid #409eff;
  /* padding-left: 88px !important; */
}
</style>