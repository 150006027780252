<template>
  <div class="about" id="quotaReviewList">
    <div style="text-align: left">
      <el-form :inline="true" :model="queryForm" class="demo-form-inline">
        <el-form-item label="合同名称：">
          <el-input v-model="queryForm.name" placeholder="请输入合同名称查询" :clearable="true"></el-input>
        </el-form-item>
        <el-form-item label="员工名称：">
          <el-input v-model="queryForm.staffName" placeholder="请输入合同名称查询" :clearable="true"></el-input>
        </el-form-item>
        <span class="demonstration">时间：</span>
        <el-date-picker value-format="yyyy-MM-dd"
                v-model="queryForm.time"
                type="daterange"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间">
          </el-date-picker>
        <el-form-item class="but">
          <el-button type="primary" @click="onQuery" icon="el-icon-search">查询</el-button>
          <el-button type="primary" @click="onReset" icon="el-icon-refresh-right">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div style="text-align: left;margin-bottom: 20px;">
        <el-button type="primary" icon="el-icon-download" @click="download()">合同PDF下载</el-button>
       </div>
    <!-- <div style="text-align: left;margin-bottom: 20px;">
      <el-button type="primary" icon="el-icon-download" @click="exportTable">导出</el-button>
    </div> -->
    <!-- <div style="display: flex;justify-content: space-between;">
      <span>汇总表编号：<b>{{queryForm.proofId}}</b></span>
      <span>上传时间：<b>{{headData.uploadTime}}</b></span>
      <span>状态：<b>{{(isEntity(summaryStateList) || isEntity(headData) || isEntity(headData.state) || isEntity(summaryStateList[headData.state]) || 
          isEntity(summaryStateList[headData.state].name))?'':summaryStateList[headData.state].name}}</b></span>
      <span>总记录：<b>{{total}}条</b></span>
    </div> -->
    <div>
        <el-table
        :data="tableData"
        :header-cell-style="tableHeader"
        :cell-style="tableCell"
        border
        fit
        @selection-change="handleSelectionChange"
        style="width: 100%">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column  type="index"  label="序号" />
        <el-table-column :show-overflow-tooltip="true" prop="name"  label="合同名称" /> 
        <el-table-column prop="signStaff" label="签署方员工" />
        <el-table-column prop="signCorp" label="签署方公司" :show-overflow-tooltip="true" />
        <el-table-column prop="signTime" label="签署时间" />
        <el-table-column prop="signFile" label="签署文件" :show-overflow-tooltip="true" />
        <!-- <el-table-column fixed="right" label="操作" v-if=" headData.state != 5" >
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="openUploadFrom(scope.row)" style="color: #09BF88">调薪</el-button>
          </template>
        </el-table-column> -->
      </el-table>
      <el-pagination
      
      id="page"
        background
        @size-change="sizeChange"
        @current-change="currentChange"
        @prev-click="currentChange"
        @next-click="currentChange"
        :page-sizes="[5, 10, 20]"
        :page-size="queryForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <div>
      <el-dialog title="调整薪资" :visible.sync="dialogFormVisible" width="50%"   style="text-align: initial;" @closed='closedDialog'>
        <el-form class="wageadjustment" :model="updateForm" :label-position="'right'"  :rules="rules" ref="updateForm" label-width="150px">
          <el-form-item label="调整后薪酬："  prop="newSalaryAmount" >
            <el-input-number v-model="updateForm.newSalaryAmount" :precision="2" :controls='false' placeholder="请输入金额" ref="inputNumber" />
            <!-- <el-input type="number" v-model="updateForm.newSalaryAmount" autocomplete="off" :clearable="true"  size="mini"  ></el-input> -->
          </el-form-item>
          <el-form-item label="备注：" prop="remark" >
            <el-input type="textarea" v-model="updateForm.remark"></el-input>
          </el-form-item>
        </el-form>
         <el-divider></el-divider>
        <div class="wageadjustment">
          <div style="margin-bottom: 10px;">调整记录：</div>
          <div>
            <el-table class="quotaReviewList" height="250" :data="logTableData" border style="width: 100%">
              <el-table-column prop="createTime" label="操作时间" />
              <el-table-column prop="oldSalaryAmount" label="调整前薪酬" ><template slot-scope="scope">{{scope.row.oldSalaryAmount/100}}</template></el-table-column>
              <el-table-column prop="newSalaryAmount" label="调整后薪酬" ><template slot-scope="scope">{{scope.row.newSalaryAmount/100}}</template></el-table-column>
              <el-table-column prop="operatorName" label="操作人" />
              <el-table-column prop="remark" label="备注" />
            </el-table>
          </div>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false;">取 消</el-button>
          <el-button type="primary" @click="submitUpdate">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>

export default {
    data() {
      return {
        headData: {
          createTime: '',
          groupId: '',
          groupName: '',
          id: '',
          latestApprovalTime: '',
          latestOperator: '',
          productId: '',
          productName: '',
          proofId: '',
          state: undefined,
          submitor: '',
          tenantId: '',
          uploadTime: ''
        },
        summaryStateList: null,
        tableLoading: true,
        loadDate:'',
        tableData: [],
        logTableData: null,
        input: '',
        urlList:[],
        queryForm: {
          id: '',
          idno: '',
          name: '',
          pageNo: 1,
          pageSize: 10,
          sessionId: this.$getCookie("sessionId"),
          time:[],
          staffName:''
        },
        total:0,
        dialogFormVisible: false,
        updateForm: {
          id: '',
          newSalaryAmount: undefined,
          remark: '',
          sessionId: ''
        },
        rules: {
          newSalaryAmount: [
            { required: true, message: '新的金额不能为空！', trigger: 'blur' }
          ]
        }
      }
    },
    async created(){
      // 获取数组字典
     
      await this.loadInfo();
      // this.loadDate = this.$route.params.date;
      await this.loadTable();
    },
    methods: {
      // 加载当前表格头
      async loadInfo(){
        // await this.api.summaryApi.getById({proofId: this.queryForm.proofId}).then(res =>{
        //   res = res.data;
        //   if(res.status = 200){
        //     this.headData = res.data;
        //   }else{
        //     this.$message.error('数据加载失败！');
        //   }
        // })
      },
      // 加载表格
      async loadTable(){
        // .bind(this);
        let flag = this;
        await this.api.contranctApi.contranctList(this.queryForm).then((res) => {
				res = res.data
				console.log("----合同列表-----",res);
				// flag.loading = false;
				if(res.code == 200){
					flag.tableData = res.data.records;
					flag.total = res.data.total
				}else{
            this.tableData =  null;
            this.total = 0;
            this.$message.error(res.msg);
          }
			})
    },
      // 条件查询
      onQuery() {
        this.loadTable();
      },
      // 修改
      openUploadFrom(row){
        this.updateForm.id =  row.id;
        let flag = this;
        let pam  = {
          id : row.id
        }
        this.api.summaryApi.getSummaryDataRecordList(pam).then(res =>{
          res = res.data
          if(res.code==200){
            flag.logTableData = res.data;
          }
        })
        this.dialogFormVisible = true;
      },
      //submitUpdate
      async submitUpdate(){
        await this.$refs['updateForm'].validate((valid) => {
          if (valid) {
            let flag = this;
            this.updateForm.sessionId = this.$getCookie("sessionId");
            flag.updateForm.newSalaryAmount = flag.updateForm.newSalaryAmount * 100
            this.api.summaryApi.updateSummaryDataAmount(this.updateForm).then(res =>{
              res = res.data;
              if(res.code == 200){
                flag.loadTable();
                flag.$message({
                  message: '修改成功！',
                  type: 'success'
                });
                flag.updateForm.id='';
                flag.updateForm.remark='';
                flag.updateForm.newSalaryAmount='';
              }else{
                flag.$message.error(res.msg);
              }
            })
            this.dialogFormVisible = false;
          } else {
            return false;
          }
        });
      },
      handleSelectionChange(val){
          this.urlList = val.map(item=>{
            return item.signFile
          })
        },
        download(){
          for(let signFile of this.urlList){
            let link = document.createElement('a')
            link.style.display = 'none'
            link.target = '_blank'
            link.href = signFile
            link.setAttribute('download', decodeURIComponent(signFile))
            document.body.appendChild(link)
            link.click();
            window.URL.revokeObjectURL(link.href);
            document.body.removeChild(link);
            console.log('下载成功！')
          }
        },
      // closedDialog
      closedDialog(){
        this.resetForm('updateForm');
        this.updateForm.newSalaryAmount = undefined;
      },
      // 重置表单
      resetForm(form){
        this.$refs[form].resetFields();
      },
      // 重置
      onReset(){
        this.queryForm.name='';
        this.queryForm.staffName=''
        this.queryForm.time=[]
      },
      //current-change
      currentChange(page){
        this.queryForm.pageNo = page;
        this.loadTable();
      },

      // size-change
      sizeChange(size){
        this.queryForm.pageSize = size;
        this.loadTable();
      },
      tableHeader(column) {
      return "height:36px;background-color:#fafafa;color:#333333;padding:0";
      },
      tableCell(column) {
        return "height:40px;background-color:#fafafa;color:#000000;padding:0";
      },
      isEntity(val){
        if(val == null || val == undefined || val === ''){
            return true;
        }else{
            return false;
        }
      },
    }
}
</script>

<style scoped>

.but{
  margin-left: 20px;
}

#page {
  float: right;
  margin-top: 15px;
}

#quotaReviewList {
  overflow: hidden;
  background-color: white;
  padding: 15px 20px 100px;
}

>>> .el-dialog__body {
  border-top: 1px solid rgba(228, 228, 228, 1);
  border-bottom: 1px solid rgb(228, 228, 228, 1);
  padding-left: 0;
  padding-right: 0;
}

>>> .el-drawer.rtl {
  overflow: scroll;
}

.wageadjustment{
  margin: 0 50px;
}

</style>

