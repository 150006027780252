import '@/plugins/axios';
import Axios from 'axios';

/**
 * 基础设置-子账户管理
 */
 export const subAccountList = params => axios.post("/subAccount/subAccountList", params).then(data => data)

//  查询用户信息
 export const findUserInfo = params => axios.post("/sysUser/findUsersById", params).then(data => data)

 // 修改支付密码
 export const editPayPwd = params => axios.post("/subAccount/updatePwd", params).then(data => data)

 // 校验手机验证码
 export const checkCode = params => axios.post("/common/authSendSms", params).then(data => data)