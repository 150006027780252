<template>
<div class="skipBody" @click="checkedTenant">
    <div class="yuan" :style="'background-color: ' + color[Math.floor((Math.random()*color.length))] +';'">{{title.charAt(0)}}</div>
    <p style="fontC">{{title}}</p>
</div>  
</template>

<script>
export default {
    props:["title","tenantId"],
    data(){
        return {
            // ,'#FFFF80'
            color:['#8080FF','#81D3F8','#8080FF','#CAF982','#FACD91','#EC808D']
        }
    },
    methods:{
        checkedTenant(){
            console.log("Accountparams-------",this.$route.params.account)
            this.api.platformApi.checkedTenant({tenantId:this.tenantId,account:this.$route.params.account}).then(resp=>{
                if(resp.data.code != 200){
                    this.$message.error("登陆租户信息失败!");
                    return;
                }
                this.$router.replace({
                    path: "/menutree",
                });
                window.location.reload();
            })
        }
    }
}
</script>

<style scoped>
.yuan{
    /* background-color: #8080FF; */
    width: 100px;
    height: 100px;
    border-radius: 100px;
    font-size: 50px;
    line-height: 100px;
    color: #fff;
    margin: auto;
}
.fontc{
    font-weight: bold;
    color: #666;
}
.skipBody{
    width: 20%;
    cursor: pointer;
    padding: 20px 0px;
}
.skipBody:hover{
    box-shadow: 0 0 25px #cac6c6;
}

</style>
