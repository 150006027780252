<template>
	<div class="tenant-container">
		<el-form status-icon  label-position="left" label-width="0px"
			class="tenant-page">
			<h3 class="title">选择平台</h3>
            <div class="tenantBody">
                <tenantSkip v-for="(tenantInfo,index) in tenantInfos" :title="tenantInfo.name" :tenantId="tenantInfo.id" :key="'skip'+index" :ref="'skip'+index"></tenantSkip>
            </div>
		</el-form>
	</div>
</template>

<script>
import tenantSkip from './tenantSkip'

export default {
    components: {
        'tenantSkip':tenantSkip //todo-item标签是todoitem.vue这个组件,使用局部组件
    },
    data(){
        return {
            tenantInfos:[],
        }
    },
    created() {
        console.log("params-------",this.$route.params.account)
        this.api.platformApi.findCurrentUserTenantInfo({account:this.$route.params.account}).then(resp=>{
            console.log(resp);
            if(resp.data.code != 200){
                this.$message.error("用户所属租户信息加载失败!");
                return;
            }
            this.tenantInfos = resp.data.data;
        });
        
    }
}
</script>

<style scoped>
.tenant-container {
		width: 100%;
		height: 100%;
	}

.tenant-page {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    margin: 180px auto;
    width: 1300px;
    padding: 35px 35px 15px;
    background: #fff;
    border: 1px solid #eaeaea;
    box-shadow: 0 0 25px #cac6c6;
}
.tenantBody{
    display: flex;
    flex-wrap: wrap;
}
</style>
