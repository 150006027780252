<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="11">
        <div id="anniu1">
          <el-row>
            <div id="anniu2">
              <el-button
                icon="el-icon-plus"
                type="primary"
                @click="popup.addDepartment = true"
                v-permissions="'添加部门'"
                >添加部门
              </el-button>
              <el-button
                icon="el-icon-plus"
                type="primary"
                @click="popup.addsubordinate = true"
                v-permissions="'添加下级'"
                >添加下级
              </el-button>
            </div>
          </el-row>
          <el-row>
            <div id="bd" style="text-align: left">
              <img src="@/static/system/u977.svg" />
              <span style="color: #565c5f">当前选择:</span>
              <span id="gname">{{ currentSelection }}</span>
              <a id="select" @click="deselect" v-if="pageSwitch">取消选择</a>
            </div>
          </el-row>

          <el-row>
            <el-input
              style="margin-bottom: 20px"
              placeholder="输入部门名称搜索"
              v-model="treeFormName"
            >
            </el-input>
          </el-row>
          <el-row>
            <el-tree
              node-key="id"
              :data="treeForm.data"
              :props="defaultProps"
              show-checkbox
              default-expand-all
              check-on-click-node
              @node-click="basicinformation"
              ref="tree"
              :check-strictly="true"
            >
            </el-tree>
          </el-row>
        </div>

        <!-- 添加部门弹出框-->
        <div>
          <el-dialog
            class="titlebox"
            title="添加部门"
            :visible.sync="popup.addDepartment"
          >
            <span>
              <el-form
                :model="groupForm"
                :rules="addGrouprules"
                ref="groupForm"
                label-width="100px"
                class="demo-ruleForm"
              >
                <el-form-item label="部门名称" prop="groupName">
                  <el-input v-model="groupForm.groupName"></el-input>
                </el-form-item>
                <el-form-item label="部门编码" prop="code">
                  <el-input v-model="groupForm.code"></el-input>
                </el-form-item>
                <el-form-item label="排序" prop="sort">
                  <el-input v-model="groupForm.sort"></el-input>
                </el-form-item>
                <el-form-item label="是否启用" prop="enable">
                  <el-radio :label="1" v-model="groupForm.enable">是</el-radio>
                  <el-radio :label="0" v-model="groupForm.enable">否</el-radio>
                </el-form-item>
                <el-form-item label="部门详述" prop="describes">
                  <el-input
                    type="textarea"
                    v-model="groupForm.describes"
                  ></el-input>
                </el-form-item>
              </el-form>
            </span>
            <span slot="footer" class="dialog-footer">
              <el-button @click="popup.addDepartment = false">取 消</el-button>
              <el-button type="primary" @click="submitForm('groupForm')"
                >新增</el-button
              >
            </span>
          </el-dialog>
        </div>

        <!--添加下级部门弹出框 -->
        <div>
          <el-dialog
            class="titlebox"
            title="添加下级部门"
            :visible.sync="popup.addsubordinate"
          >
            <span>
              <el-form
                :model="groupForm"
                :rules="addGrouprules"
                ref="groupForm"
                label-width="100px"
                class="demo-ruleForm"
              >
                <el-form-item label="下級部门" prop="groupName">
                  <el-input v-model="groupForm.groupName"></el-input>
                </el-form-item>
                <el-form-item label="上级部门" prop="parent">
                  <el-input v-model="groupList.parentName" disabled></el-input>
                </el-form-item>
                <el-form-item label="部门编码" prop="code">
                  <el-input v-model="groupForm.code" disabled></el-input>
                </el-form-item>
                <el-form-item label="排序" prop="sort">
                  <el-input v-model="groupForm.sort"></el-input>
                </el-form-item>
                <el-form-item label="是否启用" prop="enable">
                  <el-radio :label="1" v-model="groupForm.enable">是</el-radio>
                  <el-radio :label="0" v-model="groupForm.enable">否</el-radio>
                </el-form-item>
                <el-form-item label="部门详述" prop="describes">
                  <el-input
                    type="textarea"
                    v-model="groupForm.describes"
                  ></el-input>
                </el-form-item>
              </el-form>
            </span>
            <span slot="footer" class="dialog-footer">
              <el-button @click="popup.addsubordinate = false">取 消</el-button>
              <el-button
                type="primary"
                @click="addSubordinateDepartment('groupForm')"
                >新增</el-button
              >
            </span>
          </el-dialog>
        </div>

        <!--新增用户弹出框 -->
        <div>
          <el-dialog
            class="titlebox"
            title="新增用户"
            :visible.sync="popup.addUsers"
          >
            <span>
              <el-form
                :model="userForm"
                :rules="addGrouprules"
                ref="userForm"
                label-width="100px"
                class="demo-ruleForm"
              >
                <el-form-item label="账号" prop="account">
                  <el-input v-model="userForm.account"></el-input>
                </el-form-item>
                <el-form-item label="用户名" prop="name">
                  <el-input v-model="userForm.name"></el-input>
                </el-form-item>
                <el-form-item label="密碼" prop="salted">
                  <el-input v-model="userForm.salted"></el-input>
                </el-form-item>
                <el-form-item label="邮箱" prop="email">
                  <el-input v-model="userForm.email"></el-input>
                </el-form-item>
                <el-form-item label="身份证" prop="idno">
                  <el-input v-model="userForm.idno"></el-input>
                </el-form-item>
                <el-form-item label="性别" prop="sex">
                  <el-select
                    placeholder="请选择性别"
                    v-model="userForm.sex"
                    style="width: 100%"
                  >
                    <el-option label="男" value="1"></el-option>
                    <el-option label="女" value="2"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="联系方式" prop="mobile">
                  <el-input v-model="userForm.mobile"></el-input>
                </el-form-item>
                <el-form-item label="所属部门">
                  <el-input v-model="currentSelection" disabled></el-input>
                </el-form-item>

                <el-form-item
                  label="所属角色"
                  prop="role"
                  :rules="[
                    {
                      required: true,
                      message: '请选择所属角色',
                      trigger: 'change',
                    },
                  ]"
                >
                  <el-select
                    v-model="roleForm.id"
                    placeholder="请选择"
                    @change="zuidiao"
                  >
                    <el-option
                      v-for="item in roleList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-form>
            </span>
            <span slot="footer" class="dialog-footer">
              <el-button @click="popup.addUsers = false">取 消</el-button>
              <el-button type="primary" @click="addNewUser('userForm')"
                >新增</el-button
              >
            </span>
          </el-dialog>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="grid-content bg-purple">
          <div id="info">
            <div>
              <el-tabs>
                <el-tab-pane label="基础信息" class="showBox">
                  <!--基础信息表单 -->
                  <div
                    id="fromInfo"
                    :class="{ hiddenBox: !pageSwitch, showBox: pageSwitch }"
                  >
                    <el-form
                      id="msg"
                      :model="groupList"
                      :rules="addGrouprules"
                      ref="groupForm"
                      label-width="100px"
                      class="demo-ruleForm"
                    >
                      <el-form-item label="部门名称" prop="name">
                        <el-input v-model="groupList.name"></el-input>
                      </el-form-item>
                      <el-form-item label="上级部门" prop="parent">
                        <el-select
                          v-model="groupList.parentName"
                          style="width: 100%"
                          disabled
                        >
                        </el-select>
                      </el-form-item>
                      <el-form-item label="部门编码" prop="code">
                        <el-input v-model="groupList.code" disabled></el-input>
                      </el-form-item>
                      <el-form-item label="排序" prop="sort">
                        <el-input v-model="groupList.sort"></el-input>
                      </el-form-item>
                      <el-form-item label="是否启用" prop="enable">
                        <el-radio :label="1" v-model="groupList.enable"
                          >是</el-radio
                        >
                        <el-radio :label="0" v-model="groupList.enable"
                          >否</el-radio
                        >
                      </el-form-item>
                      <el-form-item label="部门详述" prop="describes">
                        <el-input
                          type="textarea"
                          v-model="groupList.describes"
                        ></el-input>
                      </el-form-item>
                      <el-form-item>
                        <el-button @click="resetForm('')">重置</el-button>
                        <el-button
                          type="primary"
                          @click="submitInfo('groupForm')"
                          v-permissions="'修改部门'"
                          >保存</el-button
                        >
                      </el-form-item>
                    </el-form>
                  </div>
                </el-tab-pane>

                <el-tab-pane label="角色列表" class="showBox">
                  <div
                    id="rolelist"
                    :class="{ hiddenBox: !pageSwitch, showBox: pageSwitch }"
                  >
                    <el-table
                      :data="roleList"
                      border
                      :header-cell-style="tableHeader"
                      :cell-style="tableCell"
                      fit
                    >
                      <el-table-column
                        type="index"
                        prop="id"
                        label="编号"
                        width="50"
                        align="center"
                      ></el-table-column>
                      <el-table-column
                        prop="code"
                        label="角色编码"
                        align="center"
                      ></el-table-column>
                      <el-table-column
                        prop="name"
                        label="角色名称"
                        align="center"
                      ></el-table-column>
                      <el-table-column
                        sortable
                        prop="timestamp"
                        label="加入时间"
                        align="center"
                      ></el-table-column>
                    </el-table>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="用户列表" class="showBox">
                  <div
                    id="anniu1"
                    style="padding-left: 0; padding-right: 0"
                    :class="{ hiddenBox: !pageSwitch }"
                  >
                    <div id="anniu5">
                      <el-button
                        icon="el-icon-plus"
                        type="primary"
                        @click="popup.addUsers = true"
                        style="margin: 0 0 10px 0"
                        >新增用户</el-button
                      >

                      <!-- v-if="funArr.indexOf('新增用户') !== -1" -->
                      <!-- 添加已有用户按钮 -->
                      <!-- <el-button
                        icon="el-icon-plus"
                        type="primary"
                        @click="findByNameUser"
                        >加入用户</el-button
                      > -->
                    </div>

                    <el-table
                      :data="userList"
                      border
                      :header-cell-style="tableHeader"
                      :cell-style="tableCell"
                      fit
                    >
                      <el-table-column
                        type="index"
                        prop="id"
                        label="编号"
                        width="50"
                        align="center"
                      ></el-table-column>
                      <el-table-column
                        prop="account"
                        label="用户账号"
                        align="center"
                      ></el-table-column>
                      <el-table-column
                        prop="name"
                        label="名字"
                        align="center"
                      ></el-table-column>
                      <el-table-column
                        prop="rname"
                        label="所属角色"
                        align="center"
                      ></el-table-column>
                      <el-table-column
                        sortable
                        prop="flag"
                        label="状态"
                        align="center"
                      >
                        <template slot-scope="scope">
                          <span v-if="scope.row.flag == 0">已启用</span>
                          <span v-if="scope.row.flag == 1">已停用</span>
                        </template>
                      </el-table-column>
                      <el-table-column
                        sortable
                        prop="timestamp"
                        label="加入时间"
                        align="center"
                      ></el-table-column>
                    </el-table>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      treeFormName: "",
      groupList: {},
      roleList: [],
      userList: [],
      pageSwitch: false,
      currentSelection: "",
      roleForm: {
        id: "",
      },
      targetData: {},
      popup: {
        addDepartment: false,
        addsubordinate: false,
        addUsers: false,
        addaAreadyUsers: false,
      },
      user: {
        id: [],
        userList: [],
      },
      userForm: {
        account: "",
        name: "",
        sex: "",
        mobile: "",
        salted: "",
        email: "",
        idno: "",
        role: "",
      },
      groupForm: {
        parent: "",
        id: "",
        code: "",
        sort: "",
        enable: 1,
        describes: "",
        groupName: "",
      },
      addGrouprules: {
        code: [
          {
            required: true,
            message: "请输入部门编码",
            trigger: "blur",
          },
        ],
        sort: [
          {
            required: true,
            message: "请输入排序",
            trigger: "blur",
          },
        ],
        account: [
          {
            required: true,
            message: "请输入账号",
            trigger: "blur",
          },
        ],
        salted: [
          {
            required: true,
            message: "请输入密碼",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "请输入邮箱",
            trigger: "blur",
          },
        ],
        idno: [
          {
            required: true,
            message: "请输入身份证",
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "请输入用戶名",
            trigger: "blur",
          },
        ],
        groupName: [
          {
            required: true,
            message: "请输入部门名称",
            trigger: "blur",
          },
        ],
        sex: [
          {
            required: true,
            message: "请选择性别",
            trigger: "change",
          },
        ],
        mobile: [
          {
            required: true,
            message: "请输入联系方式",
            trigger: "change",
          },
        ],
        enable: [
          {
            required: true,
            message: "是否启用",
            trigger: "change",
          },
        ],
        describes: [
          {
            required: true,
            message: "请输入部门详述",
            trigger: "blur",
          },
        ],
      },
      defaultProps: {
        children: "childGroups",
        label: "name",
      },
      treeForm: {
        data: [],
        id: "",
      },
      funArr: [],
    };
  },
  mounted: function () {
    this.numeralStructure();
  },
  watch: {
    treeFormName(val) {
      this.numeralStructure();
    },
  },
  created() {
    console.log("部门管理");

    this.menuPermission();
  },
  methods: {
    async menuPermission() {
      let name = "部门管理";
      let params = {
        id: this.$getCookie("sessionId"),
        menuName: name,
      };
      let response = await this.api.platformApi.menuPermission(params);

      console.log(name + "页面功能列表start---");
      console.log(response.data.data);
      console.log(name + "页面功能列表end---");
      this.funArr = response.data.data;
      console.log(this.funArr);
    },
    deselect() {
      this.pageSwitch = false;
      this.currentSelection = "";

      this.$refs.tree.setCheckedKeys([]);
    },

    tableHeader(column) {
      return "height:36px;background-color:#fafafa;color:#333333;padding:0";
    },
    tableCell(column) {
      return "height:40px;background-color:#fafafa;color:#000000;padding:0";
    },
    zuidiao(id) {
      this.roleForm.id = id;
      this.userForm.role = id;
    },
    async addNewUser(formName) {
      //新增用户按钮点击事件
      var group = this;
      await this.$refs[formName].validate((valid) => {
        if (valid) {
          var params = {
            user: group.userForm,
            groups: [[group.groupForm.parent]],
            roles: [[group.groupForm.parent, group.roleForm.id]],
          };
          group.api.platformApi.addNewUser(params).then(function (respose) {
            group.popup.addUsers = false;
            if (respose.data)
              group.$message({
                message: "添加成功",
                type: "success",
              });
            else group.$message.error("添加失败");
          });
        } else {
          return false;
        }
      });
    },
    async addSubordinateDepartment(formName) {
      //添加下级部门提交
      await this.$refs[formName].validate((valid) => {
        if (valid) {
          var params = {
            name: this.groupForm.groupName,
            code: this.groupForm.code,
            sort: this.groupForm.sort,
            enable: this.groupForm.enable,
            describes: this.groupForm.describes,
            parent: this.groupForm.parent,
            sessionId: this.$getCookie("sessionId"),
          };
          this.addgroup(params);
        } else {
          return false;
        }
      });
    },

    // 添加部门提交函数
    async addgroup(params) {
      var list = this;
      let response = await list.api.platformApi.addSubordinateDepartment(
        params
      );
      if ((response.data.data = true)) {
        list.$message({
          message: "添加成功",
          type: "success",
        });
        this.$router.go(0);
      } else {
        list.$message({
          message: "添加失败",
          type: "warning",
        });
      }
      list.popup.addDepartment = false;
      list.popup.addsubordinate = false;
      list.numeralStructure();
    },
    //基础信息提交
    async submitInfo(formName) {
      await this.$refs[formName].validate((valid) => {
        if (valid) {
          var params = {
            name: this.groupList.name,
            sort: this.groupList.sort,
            enable: this.groupList.enable,
            describes: this.groupList.describes,
            id: this.groupList.id,
            sessionId: this.$getCookie("sessionId"),
          };
          this.updateInfo(params);
        } else {
          return false;
        }
      });
    },

    async updateInfo(params) {
      var list = this;
      let response = await list.api.platformApi.submitInfo(params);
      if (response.data.code == 200) {
        list.$message({
          message: "修改成功",
          type: "success",
        });
      } else {
        list.$message({
          message: "修改失败",
          type: "warning",
        });
      }
      list.numeralStructure();
    },

    async numeralStructure() {
      //树状结构方法
      var me = this;
      var params = {
        name: this.treeFormName,
      };
      await me.api.platformApi
        .numeralStructure(params)
        .then(function (respose) {
          me.treeForm.data = respose.data.data;
          me.loadDepthTree(me.treeForm.data, 0);
          console.log(me.treeForm.data);
        });
    },
    loadDepthTree(data, depth) {
      for (var index in data) {
        let currentData = data[index];
        currentData["depth"] = depth + 1;
        if (currentData.childGroups) {
          this.loadDepthTree(currentData.childGroups, depth + 1);
        }
      }
    },

    async basicinformation(data) {
      this.$refs.tree.setCheckedKeys([data.id]);
      this.targetData = data; //保存当前对象
      //树状图点击
      this.currentSelection = data.name;
      console.log(data);
      let code = data.code.substring(0, data.code.length / data.depth);
      if (data.childGroups) {
        var charLength = 0;
        while (isNaN(code.substring(charLength, code.length))) {
          //是否是非数字值
          charLength++;
        }

        let num = Number.parseInt(code.substring(charLength, code.length));
        num += data.childGroups.length;
        console.log(code.length, charLength, num.toString().length);
        let targetCode = code.substring(0, charLength);
        //添加0 ，规则：总长度-字段长度-数值长度  =要添加0的长度
        for (
          var i = code.length - charLength - num.toString().length + 1;
          i < code.length;
          i++
        ) {
          targetCode += "0";
        }
        code = data.code + targetCode + num;
      } else {
        code = data.code + 1;
      }
      this.groupForm.code = code;
      //  this.groupForm.parentName = data.name

      this.groupForm.parent = data.id;
      var list = this;
      var params = {
        id: data.id,
      };
      await list.api.platformApi
        .basicinformation(params)
        .then(function (respose) {
          list.pageSwitch = true;
          list.groupList = respose.data.data.groupList;
          list.roleList = respose.data.data.roleList;
          // console.log(list.roleList);
          list.userList = respose.data.data.userList;
        });
    },
    //添加部门提交
    async submitForm(formName) {
      await this.$refs[formName].validate((valid) => {
        if (valid) {
          var params = {
            name: this.groupForm.groupName,
            code: this.groupForm.code,
            sort: this.groupForm.sort,
            enable: this.groupForm.enable,
            describes: this.groupForm.describes,
            parent: 0,
            sessionId: this.$getCookie("sessionId"),
          };
          this.addgroup(params);
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style scoped>
.el-dialog button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}

.titlebox {
  text-align: left;
}

#tables2 {
  width: 95%;
  margin-top: 15px;
  background-color: rgba(29, 144, 255, 0);
}

#anniu2 button {
  padding: 0px;
  width: 120px;
  height: 32px;
  border-radius: 5px;
}

.hiddenBox {
  visibility: hidden;
}

.showBox {
  background-color: white;
  padding-left: 20px;
  padding-right: 20px;
}

#anniu5 {
  width: 100%;
  height: 32px;
  border-radius: 5px;
  margin-bottom: 10px;
}

#back button {
  border-radius: 5px;
}

#back {
  background-color: white;
  padding: 15px 0 50px;
  padding: 0px;
}

#tables {
  height: 500px;
  width: 98%;
  margin-top: 5px;
  margin-left: 5px;
  background-color: rgba(29, 144, 255, 0);
}

#rolelist {
  background-color: white;
  padding: 10px 0 20px;
}

#msg {
  width: 80%;
}

>>> .el-tabs__header {
  padding: 0;
  position: relative;
  margin: 0 0 10px;
}

#info {
  width: 100%;
  height: 100%;
}

#fromInfo {
  background-color: white;
  padding: 15px 0 20px;
}

#anniu4 {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}

#table {
  width: 95%;
  margin-top: 15px;
  background-color: rgba(29, 144, 255, 0);
}

#anniu3 {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
  margin-left: 10px;
}

#add {
  padding-left: 40px;
  padding-bottom: 0px;
}

#select {
  /* margin-right: 180px; */
  color: #409eff;
}

#gname {
  display: inline-block;
  /* width: 100px; */
}

#anniu1 {
  background-color: white;
  padding: 20px 20px 200px;
  text-align: left;
}

>>> #tab-0 {
  padding-left: 20px;
}

#bd {
  height: 40px;
  line-height: 40px;
  vertical-align: middle;
  background-color: #e6f7ff;
  border-radius: 5px;
  border: 1px solid #1d90ff;
  font-size: 16px;
  margin: 20px 0 10px 0;
}

#bd > span {
  vertical-align: middle;
  margin-right: 20px;
}

#bd > img {
  vertical-align: middle;
  margin: 0 5px 0 10px;
}

>>> .el-dialog__body {
  border-top: 1px solid rgba(228, 228, 228, 1);
  border-bottom: 1px solid rgba(228, 228, 228, 1);
  padding-left: 0;
  padding-right: 0;
}

.el-select {
  width: 100%;
}

#anniu5 button {
  margin-right: 15px;
  padding: 0px;
  width: 120px;
  height: 32px;
  border-radius: 5px;
}

.demo-ruleForm {
  width: 50%;
  margin: 0 auto;
}
</style>
