import '@/plugins/axios';
import Axios from 'axios';

/**
 * 合同管理-合同列表
 */
 export const contranctList = params => axios.post("/contract/contractList", params).then(data => data)

 /**
 * 合同管理-模板列表
 */
  export const templateList = params => axios.post("/contract/templateList", params).then(data => data)

   /**
 * 合同管理-模板列表
 */
    export const sign = params => axios.post("/contract/sign", params).then(data => data)
 