<template>
  <div id="menutree">
    <el-container class="header">
      <el-aside class="header2" width="100%">
        <!-- 头部 -->
        <el-header class="header3">
          <el-row :gutter="20" style="height: 100%">
            <el-col :span="3">
              <div><span class="ii">薪金服务平台</span></div>
              </el-col>
           
            <el-col :span="5" :offset="13" style="height: 100%">
              <div class="grid-content">
                欢迎你：{{ tenantInfo.name }}
              </div>
            </el-col>
            <template>
              <!-- <div class="block">
                <span class="demonstration"></span>
                  <el-cascader
                    size="mini"
                    v-model="value"
                    :options="options"
                    @change="handleChange">
                  </el-cascader>
              </div> -->
            </template>
            <template>
              <el-button type="text" @click="open" class="edit_buttom_class"
                >退出登录</el-button>
            </template>
            <template>
              <el-button type="text" @click="changePassword" class="edit_buttom_class"
                >修改密码</el-button>
            </template>
            <!-- <template>
              <el-button type="text" @click="submit" class="edit_buttom_class"
                >切换账号</el-button
              >
            </template> -->
          </el-row>
        </el-header>
        <el-container>
          <!-- 侧边栏 -->
          <el-aside width="13%" class="sidebar_class">
            <el-menu @select="handleSelect" ref="menus" background-color="#1a1a2c" text-color="#fff">
              <tree-menus :list="menuList2" route="componentPath" title="name"></tree-menus>
            </el-menu>
          </el-aside>

          <el-container>
            <el-header id="body">
              <el-breadcrumb id="header4" separator="">
                <el-breadcrumb-item :to="{ path: '' }"
                  ><span style="color: #1d90ff">{{
                    columnName
                  }}</span></el-breadcrumb-item
                >
              </el-breadcrumb>
            </el-header>
            <!-- 主体 -->
            <el-main id="header5"><router-view></router-view></el-main>
          </el-container>
        </el-container>
      </el-aside>
    </el-container>
    <el-dialog :title="title" :visible.sync="dialogFormVisible" width="50%" style="text-align: initial;" >
          <!-- 内层 -->
          <el-form :model="updateForm" :label-position="'left'" ref="updateForm" :rules="updateFormRules" label-width="150" class="updateForm" >
            
            <div class="block">
              <span class="demonstration">一级账号：</span>
              <el-cascader
                v-model="name"
                :options="tenants"
                :props="props"
                @change="handleChange"></el-cascader>
            </div>
            <div class="block">
              <span class="demonstration">二级账号：</span>
              <el-cascader
                v-model="name2"
                :options="list"
                :props="props"
                ></el-cascader>
            </div>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" v-loading.fullscreen.lock="fullscreenLoading" @click="Exemptlogin">确 定</el-button>
          </div>
    </el-dialog>
    <el-dialog
        title="修改密码"
        :visible.sync="passwordVisible"
        width="520px">
        <el-form label-position="left" label-width="80px">
          <!-- <el-form-item label="用户名:">
            <el-input v-model="passwordForm.userName" disabled></el-input>
          </el-form-item> -->
          <el-form-item v-if="isAdmin" label="密码类型" style="text-align: left;">
            <el-radio-group v-model="passwordForm.type" @input="changeType">
              <el-radio-button label="1">登录密码</el-radio-button>
              <el-radio-button label="2">支付密码</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="新密码:">
            <el-input type="password" v-model="passwordForm.salted" placeholder="请输入密码"></el-input>
          </el-form-item>
          <el-form-item label="确认密码:">
            <el-input type="password" v-model="passwordForm.again" placeholder="请确认密码"></el-input>
          </el-form-item>
          <el-form-item label="手机号码:">
            <el-input v-model="passwordForm.mobile" placeholder="请输入手机号码" readonly></el-input>
          </el-form-item>
          <el-form-item label="验证码:">
            <el-row>
              <el-col :span="16">
                <el-input v-model="passwordForm.verifiCode" placeholder="请输入手机验证码"></el-input>
              </el-col>
              <el-col :span="8">
                <el-button style="width: 120px;" type="primary" @click="getCode" :disabled="isDisabled">
                  <span v-if="!isDisabled">获取验证码</span>
                  <el-statistic v-else :value="deadline" format="ss" time-indices :value-style="{color:'#fff',fontSize:'14px'}" @finish="finish"></el-statistic>
                </el-button>
              </el-col>
            </el-row>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="passwordVisible = false">取 消</el-button>
          <el-button type="primary" @click="passwordFormSubmit">确 定</el-button>
        </span>
</el-dialog>
  </div>
</template>



<script>
import router from "./../router/index";


import treeMenus from './treeMenus.vue';

export default {
  components: {
    treeMenus,
  },
  data() {
    return {
      isDisabled:false,
      deadline:0,
      name:'',
      name2:'',
      props:{
        label:'name',
        value:'id'
      },
      passwordForm:{
        type:'1',
        salted:'',
        again:'',
        mobile:'',
        verifiCode:''
      },
      title:'切换账号',
      menuList2: [],
      openeds: [],
      uniqueOpened: false,
      menuList: [],
      tenants:[],
      tenantInfo:'',
      userName: "",
      columnName: "用户管理",
      value: [],
      list:[],
      passwordVisible:false,
      dialogFormVisible: false,
      fullscreenLoading: false,
      icons: [
        "el-icon-s-platform",
        "el-icon-s-tools",
        "el-icon-star-on",
        "el-icon-s-fold",
        "el-icon-s-unfold",
      ],
      updateFormRules: {
        isFile:[
            { required: true, message: '请选择文件！', trigger: 'change' }
        ]
      },
      updateForm: {
        lineAmount:'',
        price:'',
        quantity:''
      },
      formInline: {
        id: "",
        tenantId:'',
      },
      loginInfo:{
        username:'',
        tenantId:'',
      },
      isAdmin:false
    };
  },

  async mounted() {
    await this.loadList2();
    this.finTenantInfo();
    this.listTenantInfos();
  },

  methods: {
    changeType(value){
      this.passwordForm = {
        type:value,
        salted:'',
        again:'',
        mobile:this.passwordForm.mobile,
        verifiCode:''
      }
    },
    async getCode(){
      await this.api.salaryApi.sendCode({mobile: this.passwordForm.mobile})
      this.isDisabled = true
      this.deadline = Date.now() + 1000 * 60
    },
    finish(){
      this.isDisabled = false
    },
    async changePassword(){
      this.passwordForm={
        type:'1',
        salted:'',
        again:'',
        mobile:this.passwordForm.mobile,
        verifiCode:''
      }
      if(!this.passwordForm.mobile){
        await this.getMobile()
      }
      this.isAdminFn()
      this.passwordVisible = true
    },
    async getMobile(){
      try {
        const tenantId = localStorage.getItem('platformAdmin').split(',')[1]
        const userId = localStorage.getItem('platform')
        const params = {
          tenantId,
          userId
        }
        const userInfo = await this.api.accountApi.findUserInfo(params)
        console.log('userInfo', userInfo)
        this.passwordForm.mobile = userInfo.data.data.mobile
      } catch (error) {
        console.log(error)
      }
    },
    isAdminFn(){
      const platformAdmin=localStorage.getItem('platformAdmin')
      let platformUserId = platformAdmin.split(',')[0]
      const userId = localStorage.getItem('platform')
      this.isAdmin = platformUserId === userId
    },
    async passwordFormSubmit(){
      try {
      let tip = ''
      if(!this.passwordForm.salted){
        this.$message.error("请输入新密码")
        return
      }
      if(!this.passwordForm.again){
        this.$message.error("请输入确认密码")
        return
      }
      if(!this.passwordForm.verifiCode){
        this.$message.error("请输入手机验证码")
        return
      }
      const response = await this.checkVerifiCode() 
      console.log('response', response)
      if(this.passwordForm.type==='1'){
        this.passwordFn()
      }
      if(this.passwordForm.type==='2'){
        this.payPassword()
      }
      } catch (error) {
        console.log('error', error)
      }
    },
    // 校验手机验证码
    async checkVerifiCode(){
      try {
        const params={
          mobile:this.passwordForm.mobile,
          verificationCode:this.passwordForm.verifiCode
        }
        const response = await this.api.accountApi.checkCode(params)
        if(response.data.code!==200){
          this.$message.error(response.data.msg)
          return Promise.reject(response.data.msg)
        }else{
          return response.data.data
        }
      } catch (error) {
        console.log(error)
      }
    },
    async passwordFn(){
      const userId = window.localStorage.getItem('platform')
        var params = {
          sessionId: this.$getCookie("sessionId"),
          user: userId,
          newPwd: this.passwordForm.salted,
        };
        let response = await this.api.platformApi.resetPwdNotVerify(params);
        let data = response.data;
        if(data.code == 200){
          this.$message({
            message: "修改成功",
            type: "success",
          });
          this.passwordVisible = false;
        }else{
          this.$message.error(data.msg);
        }
    },
    async payPassword(){
      const tenantId = localStorage.getItem('platformAdmin').split(',')[1]
      const params = {
        tenantId,
        payPwd: this.passwordForm.salted
      }
      let response = await this.api.accountApi.editPayPwd(params);
      let data = response.data;
      if(data.code == 200){
          this.$message({
            message: "修改成功",
            type: "success",
          });
          this.passwordVisible = false;
        }else{
          this.$message.error(data.msg);
        }
    },
    handleChange(value) {
        console.log("租户ID",value);
        this.formInline.tenantId = value[0]
        this.userListApi();
      },
      submit(row){
        this.dialogFormVisible = true;
      },
    async loadList2(){
      await this.api.platformApi.treeMenu({sessionId: this.$getCookie("sessionId")}).then(res => {
        let data = res.data;
        if(data.code != 200){
          // this.$message.error('菜单加载失败');
        }else{
          this.menuList2 = data.data;
        }
      })
    },
    submitUploadFrom(){
     
    },
    //查询租户下所有租户信息
    async listTenantInfos() {
      this.tableLoading = true;
      await this.api.salaryApi.finTenants(this.queryForm).then(res => {
          this.tenants = res.data;
          console.log("---一级租户--",this.tenants)
        });
    },
    async userListApi() {
				console.log(this.formInline);
				var params = {
					tenantId: this.formInline.tenantId,
					
					sessionId: this.$getCookie("sessionId"),
				};
				let response = await this.api.platformApi.findByUserManagement(params);
				this.list = response.data.data.records;
        console.log("-----二级数据",this.list);
			},
      async Exemptlogin(){
        console.log("-------免认证",this.loginInfo)
        this.loginInfo.tenantId = this.formInline.tenantId
        this.loginInfo.username  = this.list[0].name
        await this.api.salaryApi.exemptVerifyLogin(this.loginInfo).then((res) => {
          res = res.data
          console.log("----免认证登录-----",res);
          // window.localStorage.setItem("platform", res.data[0]);
					this.setCookie("sessionId", res.data[1], 1);
					this.setCookie("name", res.name);
          window.location.reload();
          
        })
      },
    // treeMenu
    async loadList() {
      var me = this;
      var params = {
        sessionId: this.$getCookie("sessionId"),
      };
      me.api.platformApi
        .PermissionMenu(params)
        .then(function (response) {
          console.log(response.data.data);
          me.menuList = response.data.data;
          for (let i = 0; i < me.menuList.length; i++) {
            me.openeds.push(me.menuList[i].module);
          }
        })
        .catch(function (error) {
          console.log(error);
        })
        .then(function () {});
    },
    
    //查询租户信息
    async finTenantInfo() {
      this.tableLoading = true;
      await this.api.salaryApi.finTenantInfo(this.queryForm).then(res => {
        console.log("-----租户",res)
          this.tenantInfo = res.data;
        });
        window.localStorage.setItem("platformAdmin", [this.tenantInfo.superAdmin,this.tenantInfo.id]);
    },
    handleOpen(key, keyPath) {},
    handleClose(key, keyPath) {},
    async handleSelect(key, keyPath,vnode) {
      console.log(key);
      let value = vnode.$vnode.elm.innerText.trim();
      this.columnName = value;
      await this.menuPermission(value,key)
       console.log(value);
    },
    
    getCookie: function (cname) {
      let name = cname + "=";
      let ca = document.cookie.split(";");
      // console.log("获取cookie,现在循环")
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        // console.log(c)
        while (c.charAt(0) === " ") c = c.substring(1);
        if (c.indexOf(name) !== -1) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    open() {
      this.$confirm("确定要退出登录吗？", "退出确认", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          this.$router.push({ path: "/login" });
        })
        .catch(() => {});
    },
    clearCookie: function (cname) {
      this.setCookie(cname, "", -1);
    },
    xx: function (columnName, target) {
      this.columnName = columnName;
    },
    handlerFoucus(event) {
      console.log(event, "xx");
    },
    setCookie: function(cname, cvalue, exdays) {
				let d = new Date();
				d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
				let expires = "expires=" + d.toUTCString();
				document.cookie = cname + "=" + cvalue + "; " + expires;
			},
			getCookie: function(cname) {
				let name = cname + "=";
				let ca = document.cookie.split(";");
				// console.log("获取cookie,现在循环")
				for (let i = 0; i < ca.length; i++) {
					let c = ca[i];
					// console.log(c)
					while (c.charAt(0) === " ") c = c.substring(1);
					if (c.indexOf(name) !== -1) {
						return c.substring(name.length, c.length);
					}
				}
				return "";
			},
			clearCookie: function(cname) {
				this.setCookie(cname, "", -1);
			},
    async menuPermission(value,path) {
      await this.$router.push({path},()=>{},()=>{});
				let response = await this.api.platformApi.menuPermission({menuName: value});
				let data = response.data.data;
        this.$bus.permissionsButton = data;
        this.$forceUpdate();
			}

      
  },
  router,
};
</script>

<style scoped>
::-webkit-scrollbar {display:none}
#menutree .sidebar_class {
  text-align: center;
  background-color: #1a1a2c !important;
  height: calc(100vh - 60px);
}
>>> .el-submenu__title{
  text-align: left;
  border: 0 !important;
}
>>> li .el-menu-item{
  border-right: 0 !important;
}
#menutree .edit_buttom_class {
  vertical-align: bottom;
  color: white;
}
#header5 {
  padding: 10px;
}
#header4 {
  line-height: 56px;
  /* height: 55px; */
}
#body {
  height: 56px !important;
  background-color: white;
}
#menutree .el-header {
  /* background-color: #b3c0d1; */
  color: rgb(252, 248, 248);
  line-height: 50px;
}
/* #menutree .el-button {
  border-top: 1px solid rgba(228, 228, 228, 1);
  border-bottom: 1px solid rgba(228, 228, 228, 1);
} */
body {
  margin: 0;
}
#menutree .ii {
  font-weight: bold;
  font-size: 16px;
  line-height: 60px;
}
#menutree .el-aside {
  background-color: white;
  color: #333;
}
>>> .el-submenu__title {
  border-bottom: 1px solid rgba(153, 153, 153, 0.2);
  background-color: #f9f9f9;
}
>>> .el-submenu.is-active .el-submenu__title {
  border-bottom: 1px solid rgba(153, 153, 153, 0.2);
}
.el-menu > .el-menu-item {
  /* padding-left: 85px !important; */
  border-bottom: 1px solid rgb(153, 153, 153, 0.2);
}


 >>> li.el-menu-item{
  border-right: 3px #f9f9f9 solid;
}
#menutree .el-menu-item.is-active {
  /* background-color: #e6f7ff !important; */
  border-right: 3px solid #409eff;
  /* padding-left: 88px !important; */
}
#menutree .el-submenu__title {
  background-color: #f9f9f9;
  border-bottom: 1px solid rgba(153, 153, 153, 0.2);
}
#menutree .header {
  border: 0px solid #eee;
}
#menutree .header2 {
  background-color: rgb(238, 241, 246);
  /* background-color: white; */
}
#menutree .header3 {
  /* background: #1d90ff; */
  background-image: url('~@/static/system/images/navbg.jpg');
  background-size: contain;
  height: 50px;
}
#menutree .grid-content {
  height: 100%;
  line-height: 60px;
}
</style>


